import {Literature} from './taxon/literature';
import {Location} from './taxon/location';
import {Synonym} from './taxon/synonym';
import {File} from './taxon/file';
import {Remark} from './taxon/remark';
import {Version} from './taxon/version';
import {Editor} from './taxon/editor';

export class Taxon {
  id: number = null;
  taxonId: number = null;
  version: number = null;
  family: string = null;
  subFamily: string = null;
  tribe: string = null;
  subTribe: string = null;
  genus: string = null;
  subGenus: string = null;
  originalGenus: string = null;
  species: string = null;
  subSpecies: string = null;
  distribution: string = null;
  firstDescription: string = null;
  describedByName: string = null;
  describedByYear: number = null;
  describedByOriginalSource: string = null;
  comments: string = null;
  dateCreated: string = null;
  dateUpdated: string = null;
  dateReleased: string = null;
  dateExpires: string = null;
  status: string = null;

  locations: Location[] = [];
  literature: Literature[] = [];
  synonyms: Synonym[] = [];
  files: File[] = [];
  remarks: Remark[] = [];
  versions: Version[] = [];
  editor: Editor = new Editor();
}
