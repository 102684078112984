<div>
  <div class="container-fluid border rounded bg-light p-3 m-0 mb-3">
    <h6>Taxonomy</h6>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect01">Family</label>
      </div>
      <select [(ngModel)]="taxon.family" data-live-search="true" id="inputGroupSelect01">
        <option value="null">- None -</option>
        <option *ngFor="let family of taxonomy?.family" [ngValue]="family" [selected]="family == taxon?.family">{{ family }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('family','Family')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect02">Subfamily</label>
      </div>
      <select (change)="taxon.subFamily = $event.target.value" data-live-search="true" id="inputGroupSelect02">
        <option value="null">- None -</option>
        <option *ngFor="let subFamily of taxonomy?.subfamily" [ngValue]="subFamily" [selected]="subFamily == taxon?.subFamily">{{ subFamily }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('subFamily','Subfamily')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect03">Tribe</label>
      </div>
      <select (change)="taxon.tribe = $event.target.value" data-live-search="true" id="inputGroupSelect03">
        <option value="null">- None -</option>
        <option *ngFor="let tribe of taxonomy?.tribe" [ngValue]="tribe" [selected]="tribe == taxon?.tribe">{{ tribe }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('tribe','Tribe')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect04">Subtribe</label>
      </div>
      <select (change)="taxon.subTribe = $event.target.value" data-live-search="true" id="inputGroupSelect04">
        <option value="null">- None -</option>
        <option *ngFor="let subtribe of taxonomy?.subtribe" [ngValue]="subtribe" [selected]="subtribe == taxon?.subTribe">{{ subtribe }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('subTribe','Subtribe')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect05">Genus</label>
      </div>
      <select (change)="taxon.genus = $event.target.value" data-live-search="true" id="inputGroupSelect05">
        <option value="null">- None -</option>
        <option *ngFor="let genus of taxonomy?.genus" [ngValue]="genus" [selected]="genus == taxon?.genus">{{ genus }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('genus','Genus')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect06">Subgenus</label>
      </div>
      <select (change)="taxon.subGenus = $event.target.value" data-live-search="true" id="inputGroupSelect06">
        <option value="null">- None -</option>
        <option *ngFor="let subGenus of taxonomy?.subgenus" [ngValue]="subGenus" [selected]="subGenus == taxon?.subGenus">{{ subGenus }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('subGenus','Subgenus')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect07">Original genus</label>
      </div>
      <select (change)="taxon.originalGenus = $event.target.value" data-live-search="true" id="inputGroupSelect07">
        <option value="null">- None -</option>
        <option *ngFor="let originalGenus of taxonomy?.originalgenus" [ngValue]="originalGenus" [selected]="originalGenus == taxon?.originalGenus">{{ originalGenus }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('originalGenus','Originalgenus')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect08">Species</label>
      </div>
      <select (change)="taxon.species = $event.target.value" data-live-search="true" id="inputGroupSelect08">
        <option value="null">- None -</option>
        <option *ngFor="let species of taxonomy?.species" [ngValue]="species" [selected]="species == taxon?.species">{{ species }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('species','Species')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>

    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupSelect09">Subspecies</label>
      </div>
      <select (change)="taxon.subSpecies = $event.target.value !== '' ? $event.target.value : null" data-live-search="true" id="inputGroupSelect09">
        <option value="null">- None -</option>
        <option *ngFor="let subSpecies of taxonomy?.subspecies" [ngValue]="subSpecies" [selected]="subSpecies == taxon?.subSpecies">{{ subSpecies }}</option>
      </select>
      <div class="input-group-append">
        <button (click)="showAddTaxonomicDataModal('subSpecies','Subspecies')" class="btn btn-outline-primary" type="button">+ Add</button>
      </div>
    </div>
  </div>

  <div class="container-fluid border rounded bg-light p-3 m-0 mb-3">
    <h6>Author/s & Year</h6>

<!--    <div class="custom-control custom-checkbox mb-2">-->
<!--      <input (change)="taxon.firstDescription = $event.target.checked ? '1' : '0'" [checked]="taxon.firstDescription === '1'" value="1" type="checkbox" class="custom-control-input" id="customCheck10">-->
<!--      <label class="custom-control-label" for="customCheck10">First description</label>-->
<!--    </div>-->

<!--    <div class="custom-control custom-checkbox mb-2">-->
<!--      <input (change)="taxon.describedByOriginalSource = $event.target.checked ? '1' : '0'" [checked]="taxon.describedByOriginalSource === '1'" value="1" type="checkbox" class="custom-control-input" id="customCheck11">-->
<!--      <label class="custom-control-label" for="customCheck11">Originally discribed in another species</label>-->
<!--    </div>-->



    <div class="input-group mb-2">
      <div class="input-group-prepend">
        <label class="input-group-text static-width-130" for="inputGroupInput405">Described by</label>
      </div>
      <input [(ngModel)]="taxon.describedByName" class="form-control" type="text" id="inputGroupInput405" placeholder="Author">
      <label class="input-group-text rounded-0" for="inputGroupInput406">in</label>
      <input [(ngModel)]="taxon.describedByYear" class="form-control" type="number" id="inputGroupInput406" placeholder="Year">
    </div>


  </div>

  <div class="container-fluid border rounded bg-light p-3 m-0 mb-3">
    <h6>Synonyms</h6>

    <div *ngFor="let synonym of taxon.synonyms; let index = index">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text static-width-130" [attr.for]="'inputGroupInput100' + index">Synonym</label>
        </div>
        <input [(ngModel)]="synonym.synonym" type="text" class="form-control" [attr.id]="'inputGroupInput100' + index">
        <div class="input-group-append">
          <button (click)="deleteSynonym(index)" class="btn btn-outline-danger" type="button">Delete</button>
        </div>
      </div>

    </div>

    <button (click)="createSynonym()" class="btn btn-outline-primary">+ Add</button>
  </div>

  <div class="container-fluid border rounded bg-light p-3 m-0 mb-3">
    <h6>Distribution</h6>

    <textarea [(ngModel)]="taxon.distribution" class="form-control">{{ taxon.distribution }}</textarea>
  </div>

<!--  <div class="container-fluid border rounded bg-light p-3 m-0">-->
<!--    <h6>Comment</h6>-->

<!--    <textarea [(ngModel)]="taxon.comments" class="form-control">{{ taxon.comments }}</textarea>-->
<!--  </div>-->

</div>

<div class="modal fade" id="addTaxonomicDataModal" tabindex="-1" role="dialog" aria-labelledby="vaddTaxonomicDataModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title" id="addTaxonomicDataModalLabel">Add {{ addTaxonomy.typeName }}</h5>
        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body overflow-auto text-wrap">

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput425">{{ addTaxonomy.typeName }}</label>
          </div>
          <input [(ngModel)]="addTaxonomy.value" class="form-control" type="text" id="inputGroupInput425" placeholder="Name">
        </div>

      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-link" data-dismiss="modal">Cancel</button>
        <button (click)="addTaxonomicData()" class="btn btn-secondary" type="button" data-dismiss="modal">Add</button>
      </div>
    </div>
  </div>
</div>
