<div class="position-fixed fixed-top" style="top: 0; left: 0">
  <div *ngFor="let notification of notification.notifications; let index = index">

    <div id="alertSaveSuccess" class="alert w-100 alert-dismissible fade show"
         [class.alert-success]="notification.type === 'INFO'"
         [class.alert-danger]="notification.type === 'ERROR'"
         [class.alert-warning]="notification.type === 'WARNING'"
         role="alert"
    >
      <strong>{{ notification.title }}</strong>
      <p [innerHTML]="notification.message"></p>
      <button [class.d-none]="notification.dissmissable === false" (click)="deleteNotification(index)" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

  </div>
</div>
