import {Component, Input, OnInit} from '@angular/core';
import {Taxon} from '../../entities/taxon';
import {Remark} from '../../entities/taxon/remark';
import {environment} from '../../../environments/environment';
import {FilePreviewService} from '../../services/file-preview.service';
import {UiUpdateService} from '../../services/ui-update.service';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.css']
})
export class RemarksComponent implements OnInit {

  @Input() taxon: Taxon;
  environment = environment;

  remark: Remark = null;
  selectedIndex: number = null;

  constructor(
    readonly filePreview: FilePreviewService,
    readonly ui: UiUpdateService
  ) {
    window.addEventListener('message', (event)  => {
      if (event.data[0] === 'upload-complete' && event.data[1] === 'uploadRemarkComplete') {
        this.remark.reference = event.data[2][0].uuid;
        this.hideUploadModal();
      }
    });
  }

  ngOnInit(): void {
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  createRemark() {
    const remark = new Remark();
    remark.taxonId = this.taxon.taxonId;

    this.taxon.remarks.push(remark);
    this.remark = remark;

    this.ui.updateSelectPicker();
  }

  deleteRemark(index: number) {
    this.taxon.remarks.splice(index, 1);
    this.remark = null;
    this.ui.updateSelectPicker();
  }

  deleteReference() {
    this.remark.reference = null;
    this.ui.updateSelectPicker();
  }

  uploadRemark() {
    // @ts-ignore
    document.getElementById('frame-upload3').contentWindow.postMessage(['upload-start'], '*');
  }

  showUploadModal() {
    $('#frame-upload3').attr('src', $('#frame-upload3').attr('src'));
  }

  hideUploadModal() {
    // @ts-ignore
    $('#uploadModal3').delay(500).modal('hide');
  }

}
