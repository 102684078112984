export class Remark {
  id: number = null;
  taxonId: number = null;
  version: number = null;
  publishedByName: string = null;
  publishedByYear: number = null;
  title: string = null;
  remarkType: string = 'RM';
  content: string = null;
  reference: string = null;
  dateCreated: string = null;
  dateUpdated: string = null;
  dateReleased: string = null;
  dateExpires: string = null;
  status: string = null;
}
