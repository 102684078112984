<button *ngIf="environment.debug" class="btn btn-info" data-toggle="collapse" data-target="#demo">Show Files JSON</button>
<pre *ngIf="environment.debug" id="demo" class="collapse border p-3">
  {{ taxon.files | json }}
</pre>

<nav>
  <button (click)="showUploadModal()" class="btn btn-outline-primary float-right" data-toggle="modal" data-target="#uploadModal">+ Add figure</button>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">

    <a (click)="selectedFigureType = 'HABITUS'" class="nav-item nav-link active" id="nav-habitus-tab" data-toggle="tab" href="#nav-habitus" role="tab" aria-controls="nav-habitus" aria-selected="true">Habitus</a>
    <a (click)="selectedFigureType = 'GENITALIA'"  class="nav-item nav-link" id="nav-genitalia-tab" data-toggle="tab" href="#nav-genitalia" role="tab" aria-controls="nav-genitalia" aria-selected="false">Genitalia</a>
    <a (click)="selectedFigureType = 'IDENTIFICATION'"  class="nav-item nav-link" id="nav-identification-tab" data-toggle="tab" href="#nav-identification" role="tab" aria-controls="nav-identification" aria-selected="false">Identification</a>
    <a (click)="selectedFigureType = 'BIOLOGY'"  class="nav-item nav-link" id="nav-biology-tab" data-toggle="tab" href="#nav-biology" role="tab" aria-controls="nav-biology" aria-selected="false">Biology</a>
    <a (click)="selectedFigureType = 'DISPERSALAREA'"  class="nav-item nav-link" id="nav-dispersal-area-tab" data-toggle="tab" href="#nav-dispersal-area" role="tab" aria-controls="nav-dispersal-area" aria-selected="false">Dispersal area</a>
  </div>
</nav>
<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active border border-top-0 p-3" id="nav-habitus" role="tabpanel" aria-labelledby="nav-habitus-tab">
    <div class="card-deck">

      <ng-container *ngFor="let file of taxon.files; let index = index">
        <div *ngIf="file.figureType === 'HABITUS'" class="card mw-250">
          <img (click)="this.filePreview.previewFile(file.file)" class="card-img-top cursor-pointer" [attr.src]="environment.cdnBaseUrl + '/' + file.file" alt="{{ file.title }}">
          <div class="card-body">

          </div>
          <div class="card-footer p-1">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" [attr.for]="'inputGroupInput' + index">Title</label>
              </div>
              <input type="text" [(ngModel)]="file.title" class="form-control" [attr.id]="'inputGroupInput' + index">
            </div>
            <button (click)="deleteFigure(index);" class="btn btn-outline-danger">Delete figure</button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <div class="tab-pane fade border border-top-0 p-3" id="nav-genitalia" role="tabpanel" aria-labelledby="nav-genitalia-tab">
    <div class="card-deck">

      <ng-container *ngFor="let file of taxon.files; let index = index">
        <div *ngIf="file.figureType === 'GENITALIA'" class="card mw-250">
          <img (click)="this.filePreview.previewFile(file.file)" class="card-img-top cursor-pointer" [attr.src]="environment.cdnBaseUrl + '/' + file.file" alt="{{ file.title }}">
          <div class="card-body">

          </div>
          <div class="card-footer p-1">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" [attr.for]="'inputGroupInput' + index">Title</label>
              </div>
              <input type="text" [(ngModel)]="file.title" class="form-control" [attr.id]="'inputGroupInput' + index">
            </div>
            <button (click)="deleteFigure(index);" class="btn btn-outline-danger">Delete figure</button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <div class="tab-pane fade border border-top-0 p-3" id="nav-identification" role="tabpanel" aria-labelledby="nav-identification-tab">
    <div class="card-deck">
      <ng-container *ngFor="let file of taxon.files; let index = index">
        <div *ngIf="file.figureType === 'IDENTIFICATION'" class="card mw-250">
          <img (click)="this.filePreview.previewFile(file.file)" class="card-img-top cursor-pointer" [attr.src]="environment.cdnBaseUrl + '/' + file.file" alt="{{ file.title }}">
          <div class="card-body">

          </div>
          <div class="card-footer p-1">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" [attr.for]="'inputGroupInput' + index">Title</label>
              </div>
              <input type="text" [(ngModel)]="file.title" class="form-control" [attr.id]="'inputGroupInput' + index">
            </div>
            <button (click)="deleteFigure(index);" class="btn btn-outline-danger">Delete figure</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="tab-pane fade border border-top-0 p-3" id="nav-biology" role="tabpanel" aria-labelledby="nav-biology-tab">
    <div class="card-deck">
      <ng-container *ngFor="let file of taxon.files; let index = index">
        <div *ngIf="file.figureType === 'BIOLOGY'" class="card mw-250">
          <img (click)="this.filePreview.previewFile(file.file)" class="card-img-top cursor-pointer" [attr.src]="environment.cdnBaseUrl + '/' + file.file" alt="{{ file.title }}">
          <div class="card-body">

          </div>
          <div class="card-footer p-1">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" [attr.for]="'inputGroupInput' + index">Title</label>
              </div>
              <input type="text" [(ngModel)]="file.title" class="form-control" [attr.id]="'inputGroupInput' + index">
            </div>
            <button (click)="deleteFigure(index);" class="btn btn-outline-danger">Delete figure</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="tab-pane fade border border-top-0 p-3" id="nav-dispersal-area" role="tabpanel" aria-labelledby="nav-dispersal-area-tab">
    <div class="card-deck">
      <ng-container *ngFor="let file of taxon.files; let index = index">
        <div *ngIf="file.figureType === 'DISPERSALAREA'" class="card mw-250">
          <img (click)="this.filePreview.previewFile(file.file)" class="card-img-top cursor-pointer" [attr.src]="environment.cdnBaseUrl + '/' + file.file" alt="{{ file.title }}">
          <div class="card-body">

          </div>
          <div class="card-footer p-1">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <label class="input-group-text" [attr.for]="'inputGroupInput' + index">Title</label>
              </div>
              <input type="text" [(ngModel)]="file.title" class="form-control" [attr.id]="'inputGroupInput' + index">
            </div>
            <button (click)="deleteFigure(index);" class="btn btn-outline-danger">Delete figure</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title" id="uploadModalLabel">Upload file</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">

        <div class="m-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text static-width-130" for="inputGroupSelect100">Type</label>
            </div>
            <select [(ngModel)]="selectedFigureType" class="custom-select bg-light" data-live-search="true" id="inputGroupSelect100">
              <option value="HABITUS">Habitus</option>
              <option value="GENITALIA">Genitalia</option>
              <option value="IDENTIFICATION">Identification</option>
              <option value="BIOLOGY">Biology</option>
              <option value="DISPERSALAREA">Dispersal area</option>
            </select>
          </div>
        </div>
        <iframe id="frame-upload" src="https://cdn.peterstueben.com/upload?callbackFunction=uploadFigureComplete&automaticUpload=0" class="border-0 m-0 p-0 w-100" style="height: 370px"></iframe>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="uploadFigure()">Upload</button>
      </div>
    </div>
  </div>
</div>
