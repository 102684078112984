import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Taxon} from '../../entities/taxon';
import {Taxonomy} from '../../entities/taxonomy';
import {Country} from '../../entities/country';
import {Author} from '../../entities/author';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../../services/notification.service';
import {NomenclatureService} from '../../services/nomenclature.service';
import {UiUpdateService} from '../../services/ui-update.service';

@Component({
  selector: 'app-taxon',
  templateUrl: './taxon.component.html',
  styleUrls: ['./taxon.component.css']
})
export class TaxonComponent implements OnInit {

  @Input() taxonId?: number;

  environment = environment;

  taxon: Taxon = null;
  originalTaxon: string = null;
  taxons: Taxon[] = [];
  taxonomy: Taxonomy;
  countries: Country[];
  authors: Author[];
  showEditArea: boolean = false;
  validationErrors: [] = null;
  saveResult: Taxon = null;

  taxonChangedWarningText: string = 'Your data has changed. Do you want to change / leave this page?';

  constructor(
    readonly api: ApiService,
    readonly route: ActivatedRoute,
    readonly notification: NotificationService,
    readonly nomenclature: NomenclatureService,
    readonly router: Router,
    readonly ui: UiUpdateService
  ) {
    window.addEventListener('beforeunload', (event) => {
      return this.warnUnsavedChanges(event);
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.taxonId = params['taxonId'] ? +params['taxonId'] : null;

      this.showEditArea = false;

      if (this.taxonId) {
        this.getTaxon(this.taxonId);
      }

      if (this.taxons.length <= 0) {
        this.api.getTaxons().then((data) => {
          this.taxons = data;
          this.ui.updateSelectPicker();
        });
      }

      this.api.getTaxonomy().then((data) => {
        this.taxonomy = data;
      });

      this.api.getCountries().then((data) => {
        this.countries = data;
      });

      this.api.getAuthors().then((data) => {
        this.authors = data;
      });
    });
  }

  getTaxon(taxonId: number) {
    this.api.getTaxon(taxonId).then(data => {
      this.showEditArea = true;
      this.taxon = data;
      this.originalTaxon = JSON.stringify(data);
    });
  }

  loadTaxon(taxonId: number) {

    if (this.taxonIsChanged()) {
      if (!confirm(this.taxonChangedWarningText)) {
        return;
      }
    }

    this.getTaxon(taxonId);

    this.router
      .navigate(['taxon/edit/' + taxonId])
      .then((loaded) => {
        this.showEditArea = true;
        this.ui.updateSelectPicker();
      })
      .catch();


    this.originalTaxon = JSON.stringify(this.taxon);

  }

  saveTaxon() {
    const taxonToSave = this.taxon;
    taxonToSave.id = null;
    taxonToSave.version = null;
    this.api.saveTaxon(this.taxon)
      .then(data => {
        if (data === null) {
          alert('There was an error saving your data');
        }
        if (data.hasOwnProperty('taxonId')) {
          this.taxon.id = data.id;
          this.taxon.taxonId = data.taxonId;
          this.taxon.version = data.version;
          this.validationErrors = null;
          this.saveResult = data;
          this.originalTaxon = JSON.stringify(this.taxon);

          this.notification.info(
            'Your data has been saved',
            'The current version is now: Version ' + data.version
            // '<br><br>' +
            // '<a href="https://catalogues.peterstueben.com/taxons/show/' + data.taxonId + '/' + data.version + '" target="_blank" rel="noopener">Preview</a>'
          );
          this.ui.updateSelectPicker();
        }
        if (data.hasOwnProperty('validation')) {
          this.validationErrors = data.validation;
          this.saveResult = null;
          this.showValidationModalError();
        }
      })
      .catch(error => {
        if (environment.debug) {
          console.log(error);
        }
        let message = error?.error?.error || error?.error?.message;
        this.notification.error('Error while saving data', message);
      })
    ;
  }

  revertTaxon() {
    if (confirm('Are you sure you want to revert your changes?')) {
      this.notification.info('Info', 'Your changes have been reverted');
      this.taxon = JSON.parse(this.originalTaxon);
    }
  }

  showValidationModalError() {
    // @ts-ignore
    $('#validationModalError').modal('show');
  }

  showValidationModalSuccess() {
    // @ts-ignore
    $('#validationModalSuccess').modal('show');
  }

  deleteTaxon() {
    if (confirm('Are you sure you want to delete this entry?')) {
      this.api.deleteTaxon(this.taxon.taxonId).then(data => {
        window.location.reload();
      }).catch(error => {
        if (error?.error?.code === 404) {
          this.originalTaxon = JSON.stringify(this.taxon);
          window.location.reload();
        }
      });
    }
  }

  createTaxon() {
    if (this.taxonIsChanged()) {
      if (!confirm(this.taxonChangedWarningText)) {
        return;
      }
    }
    this.taxon = new Taxon();
    this.taxons.push(this.taxon);
    this.originalTaxon = JSON.stringify(this.taxon);
    this.showEditArea = true;
    this.ui.updateSelectPicker();
  }

  taxonIsChanged(): boolean {
    return this.taxon != null && this.originalTaxon !== null && JSON.stringify(this.taxon) !== this.originalTaxon;
  }

  warnUnsavedChanges(event) {
    if (this.taxonIsChanged()) {
      event.preventDefault();
      event.returnValue = this.taxonChangedWarningText;
      return event;
    } else {
      return null;
    }
  }

  validateTaxon() {
    this.api.validateTaxon(this.taxon)
        .then(result => {
          console.log(result);
          if (result !== null && result.validation !== null) {
            this.validationErrors = result.validation;
            this.showValidationModalError();
          } else {
            this.validationErrors = null;
            this.showValidationModalSuccess();
          }
        })
        .catch(error => {
          this.notification.error('Error while validation', error);
        });
  }

}
