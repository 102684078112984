<div class="input-group">
  <div class="input-group-prepend">
    <label class="input-group-text static-width-130" for="inputGroupSelect02">Literature</label>
  </div>
  <select [class.d-none]="taxon?.literature?.length <= 0" [(ngModel)]="literature" (change)="setSelectedIndex($event.target.selectedIndex)" id="inputGroupSelect02">
    <option *ngFor="let literature of taxon?.literature; let index = index" [ngValue]="literature">{{ literature.title || '- New -' }}</option>
  </select>
  <button [class.d-none]="literature?.taxonId === null" (click)="deleteLiterature(selectedIndex)" class="btn btn-outline-danger rounded-0" type="button">Delete</button>
  <div class="input-group-append">
    <button (click)="createLiterature()" class="btn btn-outline-primary" type="button">+ Add</button>
  </div>
</div>

<button *ngIf="environment.debug" class="btn btn-info" data-toggle="collapse" data-target="#demo">Show Literature JSON</button>
<pre *ngIf="environment.debug" id="demo" class="collapse border p-3">
  {{ literature | json }}
</pre>

<ng-template [ngIf]="literature" >
  <div class="row" [class.d-none]="taxon?.literature?.length <= 0">
    <div class="col">
      <hr>

      <h5>Literature references</h5>

      <div class="container-fluid border rounded bg-light p-3 m-0 mb-3">
        <h6>Bibliography</h6>

        <div class="custom-control custom-radio ">
          <input [(ngModel)]="literature.literatureType" [checked]="literature.literatureType === 'FD'" value="FD" type="radio" id="literatureTypeFD" name="literatureType" class="custom-control-input">
          <label class="custom-control-label" for="literatureTypeFD">First description</label>
        </div>
        <div class="custom-control custom-radio mb-2">
          <input [(ngModel)]="literature.literatureType" value="FR" type="radio" id="literatureTypeFR" name="literatureType" class="custom-control-input">
          <label class="custom-control-label" for="literatureTypeFR">Further references</label>
        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput700">Published by</label>
          </div>
          <input [(ngModel)]="literature.publishedByName" class="form-control" id="inputGroupInput700" type="text" placeholder="Author">
          <label class="input-group-text rounded-0" for="inputGroupInput701">in</label>
          <input [(ngModel)]="literature.publishedByYear" class="form-control" id="inputGroupInput701" type="number" placeholder="Year">
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput702">Title</label>
          </div>
          <input [(ngModel)]="literature.title" class="form-control" id="inputGroupInput702" type="text" placeholder="">
        </div>

      </div>

      <div class="container-fluid border rounded bg-light p-3 m-0">
        <h6>Reference</h6>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="custom-control custom-radio input-group-text text-left static-width-130">
              <div class="ml-2">
                <input [(ngModel)]="literature.referenceType" value="null" type="radio" id="referenceTypeNONE" name="referenceType" class="custom-control-input">
                <label class="custom-control-label w-100" for="referenceTypeNONE">None</label>
              </div>
            </div>
          </div>
        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="custom-control custom-radio input-group-text text-left static-width-130">
              <div class="ml-2">
                <input [(ngModel)]="literature.referenceType" value="EXTERNAL" type="radio" id="referenceTypeEXTERNAL" name="referenceType" class="custom-control-input">
                <label class="custom-control-label w-100" for="referenceTypeEXTERNAL">External</label>
              </div>
            </div>
          </div>
          <input [(ngModel)]="literature.reference" [disabled]="literature.referenceType !== 'EXTERNAL'" class="form-control" [class.text-transparent]="literature.referenceType !== 'EXTERNAL'" type="text" id="inputGroupInput704">
          <div class="input-group-append">
            <button (click)="this.filePreview.previewLink(literature.reference)" class="btn btn-outline-secondary" [disabled]="literature.referenceType !== 'EXTERNAL'">Preview</button>
          </div>
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <div class="custom-control custom-radio input-group-text text-left static-width-130">
              <div class="ml-2">
                <input [(ngModel)]="literature.referenceType" value="INTERNAL" type="radio" id="referenceTypeINTERNAL" name="referenceType" class="custom-control-input">
                <label class="custom-control-label w-100" for="referenceTypeINTERNAL">File</label>
              </div>
            </div>
          </div>
          <input readonly [class.bg-white]="literature.referenceType === 'INTERNAL'" [(ngModel)]="literature.reference" [disabled]="literature.referenceType !== 'INTERNAL'" class="form-control" [class.text-transparent]="literature.referenceType !== 'INTERNAL'" type="text" id="inputGroupInput705" >
          <button (click)="showUploadModal()" data-toggle="modal" data-target="#uploadModal2" class="btn btn-outline-secondary rounded-0" [disabled]="literature.referenceType !== 'INTERNAL'">Upload...</button>
          <div class="input-group-append">
            <button (click)="this.filePreview.previewFile(literature.reference)" class="btn btn-outline-secondary" [disabled]="literature.referenceType !== 'INTERNAL'">Preview</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</ng-template>


<div class="modal fade" id="uploadModal2" tabindex="-1" role="dialog" aria-labelledby="uploadModal2Label" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title" id="uploadModal2Label">Upload file</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <iframe id="frame-upload2" src="https://cdn.peterstueben.com/upload?callbackFunction=uploadReferenceComplete&automaticUpload=0'" class="border-0 m-0 p-0 w-100" style="height: 370px"></iframe>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="uploadReference()">Upload</button>
      </div>
    </div>
  </div>
</div>
