import {Component, Input, OnInit} from '@angular/core';
import {Taxon} from '../../entities/taxon';
import {Taxonomy} from '../../entities/taxonomy';
import {Synonym} from '../../entities/taxon/synonym';
import {UiUpdateService} from '../../services/ui-update.service';

@Component({
  selector: 'app-taxonomic-data',
  templateUrl: './taxonomic-data.component.html',
  styleUrls: ['./taxonomic-data.component.css']
})
export class TaxonomicDataComponent implements OnInit {

  @Input() taxon: Taxon = null;
  @Input() taxonomy: Taxonomy;

  addTaxonomy: {
    typeName: string,
    typeId: string,
    value: string
  } = {
    typeName: null,
    typeId: null,
    value: null
  };

  constructor( readonly ui: UiUpdateService ) { }

  ngOnInit(): void {
    if (this.taxon === null) {
      this.taxon = new Taxon();
    }
  }

  deleteSynonym(index: number) {
    this.taxon.synonyms.splice(index, 1);
  }

  createSynonym() {
    const synonym: Synonym = new Synonym();
    synonym.taxonId = this.taxon.id;

    this.taxon.synonyms.push(synonym);
  }

  addTaxonomicData() {
    this.taxonomy[this.addTaxonomy.typeId.toLowerCase()].push(this.addTaxonomy.value);
    this.taxon[this.addTaxonomy.typeId] = this.addTaxonomy.value;
  }

  showAddTaxonomicDataModal(typeId: string, typeName: string) {
    this.addTaxonomy.typeName = typeName;
    this.addTaxonomy.typeId = typeId;
    this.addTaxonomy.value = null;
    // @ts-ignore
    $('#addTaxonomicDataModal').modal('show');
  }

}
