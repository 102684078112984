export class Synonym {
  id: number = null;
  taxonId: number = null;
  version: number = null;
  synonym: string = null;
  dateCreated: string = null;
  dateUpdated: string = null;
  dateReleased: string = null;
  dateExpires: string = null;
  status: string = null;
}
