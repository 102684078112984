import { Component, OnInit } from '@angular/core';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(
    readonly notification: NotificationService
  ) { }

  ngOnInit(): void {
  }

  deleteNotification(index) {
    this.notification.deleteNotification(index);
  }

  ngAfterViewChecked(): void {
    //$('.toast').toast('show');
  }

}
