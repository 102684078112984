import {Component, Input, OnInit} from '@angular/core';
import {Taxon} from '../../entities/taxon';
import {File} from '../../entities/taxon/file';
// @ts-ignore
import $ = require('jquery');
import {environment} from '../../../environments/environment';
import {FilePreviewService} from '../../services/file-preview.service';

@Component({
  selector: 'app-figures',
  templateUrl: './figures.component.html',
  styleUrls: ['./figures.component.css']
})
export class FiguresComponent implements OnInit {

  @Input() taxon: Taxon = null;
  selectedFigureType: string = 'HABITUS';
  environment = environment;

  constructor(
    readonly filePreview: FilePreviewService
  ) {
    window.addEventListener('message', (event)  => {
      if (event.data[0] === 'upload-complete' && event.data[1] === 'uploadFigureComplete') {
        this.createFigure(event.data[2][0].uuid);
        this.hideUploadModal();
      }
    });
  }

  ngOnInit(): void {
    if (this.taxon === null) {
      this.taxon = new Taxon();
    }
  }

  deleteFigure(index: number) {
    this.taxon.files.splice(index, 1);
  }

  uploadFigure() {
    // @ts-ignore
    document.getElementById('frame-upload').contentWindow.postMessage(['upload-start'], '*');
  }

  showUploadModal() {
    $('#frame-upload').attr('src', $('#frame-upload').attr('src'));
  }

  hideUploadModal() {
    // @ts-ignore
    $('#uploadModal').delay(500).modal('hide');
  }

  createFigure(uuid: string) {
    const figure = new File();
    figure.taxonId = this.taxon.taxonId;
    figure.file = uuid;
    figure.fileType = 'FIGURE';
    figure.figureType = this.selectedFigureType;

    this.taxon.files.push(figure);
  }

}
