import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {TaxonComponent} from './components/taxon/taxon.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {TaxonomicDataComponent} from './components/taxonomic-data/taxonomic-data.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LocationsComponent} from './components/locations/locations.component';
import {FiguresComponent} from './components/figures/figures.component';

import {RouterModule, Routes} from '@angular/router';
import {LiteratureComponent} from './components/literature/literature.component';
import {RemarksComponent} from './components/remarks/remarks.component';
import {NotificationComponent} from './components/notification/notification.component';

declare var jQuery:any;
declare var $:any;

const appRoutes: Routes = [
  {
    path: 'taxon/edit/:taxonId',
    component: TaxonComponent
  },
  {
    path: '**',
    redirectTo: 'taxon/edit/'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    TaxonComponent,
    NavigationComponent,
    TaxonomicDataComponent,
    LocationsComponent,
    FiguresComponent,
    LiteratureComponent,
    RemarksComponent,
    NotificationComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: false} // <-- debugging purposes only
    ),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
