

<div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text static-width-130" for="inputGroupSelect02">Taxon</label>
  </div>
    <select (change)="loadTaxon($event.target.value)" data-live-search="true" data-width="auto" id="inputGroupSelect02">
      <option selected disabled>Select...</option>
      <option *ngFor="let taxon of taxons" [value]="taxon.taxonId">{{ nomenclature.getTaxonCaption(taxon) || '- New -' }}</option>
    </select>
  <div class="input-group-append">
    <button (click)="createTaxon()" class="btn btn-outline-primary" type="button">+ New</button>
  </div>
</div>

<ng-template [ngIf]="taxon" >
  <div [class.d-none]="taxon === null">
    <div class="row h-100">
      <div class="col-md-7 my-auto">
        <h5 [innerHTML]="nomenclature.getTaxonCaption(taxon, true) || '- New -'"></h5>
      </div>
      <div class="col-md-5">

        <div class="float-right">
          <div class="btn-group mr-2">
            <button [class.d-none]="!taxonIsChanged()" (click)="revertTaxon()" class="btn btn-outline-secondary float-right">Revert</button>
            <button (click)="validateTaxon()" class="btn btn-outline-success" [class.rounded-left]="!taxonIsChanged()" type="button">Validate</button>
            <button (click)="deleteTaxon()" class="btn btn-outline-danger" type="button">Delete</button>
          </div>
          <button [class.d-none]="!taxonIsChanged()" (click)="saveTaxon()" class="btn btn-success float-right">Save</button>
        </div>

      </div>
    </div>

    <hr>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active" id="nav-taxonomic-data-tab" data-toggle="tab" href="#nav-taxonomic-data" role="tab" aria-controls="nav-taxonomic-data" aria-selected="true">Taxonomic data</a>
        <a class="nav-item nav-link" id="nav-locations-tab" data-toggle="tab" href="#nav-locations" role="tab" aria-controls="nav-locations" aria-selected="false">Locations & DNA</a>
        <a class="nav-item nav-link" id="nav-figures-tab" data-toggle="tab" href="#nav-figures" role="tab" aria-controls="nav-figures" aria-selected="false">Figures</a>
        <a class="nav-item nav-link" id="nav-literature-tab" data-toggle="tab" href="#nav-literature" role="tab" aria-controls="nav-literature" aria-selected="false">Literature</a>
        <a class="nav-item nav-link" id="nav-remarks-tab" data-toggle="tab" href="#nav-remarks" role="tab" aria-controls="nav-remarks" aria-selected="false">Proposals & Comments</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active p-3 border border-top-0 bg-white" id="nav-taxonomic-data" role="tabpanel" aria-labelledby="nav-taxonomic-data-tab">
        <app-taxonomic-data [taxonomy]="taxonomy" [taxon]="taxon"></app-taxonomic-data>
      </div>
      <div class="tab-pane fade p-3 border border-top-0 bg-white" id="nav-locations" role="tabpanel" aria-labelledby="nav-locations-tab">
        <app-locations [taxonomy]="taxonomy" [taxon]="taxon" [countries]="countries" [authors]="authors"></app-locations>
      </div>
      <div class="tab-pane fade p-3 border border-top-0 bg-white" id="nav-figures" role="tabpanel" aria-labelledby="nav-figures-tab">
        <app-figures [taxon]="taxon"></app-figures>
      </div>
      <div class="tab-pane fade p-3 border border-top-0 bg-white" id="nav-literature" role="tabpanel" aria-labelledby="nav-literature-tab">
        <app-literature [taxon]="taxon"></app-literature>
      </div>
      <div class="tab-pane fade p-3 border border-top-0 bg-white" id="nav-remarks" role="tabpanel" aria-labelledby="nav-figures-tab">
        <app-remarks [taxon]="taxon"></app-remarks>
      </div>
    </div>


    <button *ngIf="environment.debug" class="btn btn-info" data-toggle="collapse" data-target="#demo">Show Taxon JSON</button>
    <pre *ngIf="environment.debug" id="demo" class="collapse border p-3">
      {{ taxon | json }}
    </pre>
  </div>
</ng-template>


  <div class="modal fade" id="validationModalError" tabindex="-1" role="dialog" aria-labelledby="validationModalErrorLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white">
          <h5 class="modal-title" id="validationModalErrorLabel">Validation failed</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body overflow-auto text-wrap">
          <strong>Your data was not saved.</strong><br>
          <p>There are some errors. Please fix them first:</p>
          <ul>
            <li *ngFor="let validationError of validationErrors">
              {{ validationError }}
            </li>
          </ul>
          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample1">
            Details
          </button>
          <div class="collapse" id="collapseExample1">
            <div class="card card-body">
              <pre>{{ validationErrors | json }}</pre>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="validationModalSuccess" tabindex="-1" role="dialog" aria-labelledby="validationModalSuccessLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header bg-success text-white">
          <h5 class="modal-title" id="validationModalSuccessLabel">Validation finished</h5>
          <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body overflow-auto text-wrap">
          <strong>Your data is valid</strong><br>
          <p>No errors or invalid data was found.</p>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

