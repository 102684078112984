
export class Location {
  altitudeFrom: number = null;
  altitudeTo: number = null;
  determinedBy: string = null;
  collectedBy: string = null;
  collection: string = null;
  comments: string = null;
  dateRecorded: string = null;
  dateCreated: string = null;
  dateReleased: string = null;
  dateUpdated: string = null;
  dnaSequence16S: string = null;
  dnaSequence28S: string = null;
  dnaSequenceCO1: string = null;
  geoCity: string = null;
  geoCountry: string = null;
  geoRegion: string = null;
  geoSpot: string = null;
  id: number = null;
  latitude: number = null;
  locusTypicus: string = null;
  longitude: number = null;
  plant: string = null;
  quantity: number = null;
  status: string = null;
  taxonId: number = null;
  version: number = null;

}
