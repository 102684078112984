import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '../../environments/environment';
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.apiBaseUrl;
  projectTheMacaronesianIslands = 'TheMacaronesianIslands';
  projectTaxonomicData = 'TaxonomicData';

  constructor(
    readonly http: HttpClient,
    readonly notification: NotificationService
  ) { }

  getTaxon(taxonId: number, version: number = null): Promise<any>|null {
    return this.call(this.projectTheMacaronesianIslands, '/taxon/' + taxonId + (version != null ? '/' + version : ''));
  }

  getTaxons(): Promise<any>|null {
    return this.call(this.projectTheMacaronesianIslands, '/taxons/list');
  }

  getTaxonomy() {
    return this.call(this.projectTaxonomicData, '/taxonomy/list');
  }

  getCountries() {
    return this.call(this.projectTaxonomicData, '/countries');
  }

  getAuthors() {
    return this.call(this.projectTaxonomicData, '/authors/short');
  }

  saveTaxon(taxon: object) {
    return this.call(this.projectTheMacaronesianIslands, '/taxon/save', taxon, 'POST');
  }

  deleteTaxon(taxonId: number) {
    return this.call(this.projectTheMacaronesianIslands, '/taxon/delete/' + taxonId, null, 'POST');
  }

  validateTaxon(taxon: object) {
    return this.call(this.projectTheMacaronesianIslands, '/taxon/validate', taxon, 'POST');
  }

  private call(projectName: string, url: string, payload: object = null, method: 'GET'|'POST'|'PUT'|'DELETE' = 'GET'): Promise<any>|null {
    try {
      const fullUrl = this.baseUrl + '/' + projectName + url;
      let response : Observable<object> = null;

      if (environment.debug) {
        console.log(method, fullUrl, payload);
      }

      switch (method.toUpperCase()) {
        case 'GET':
          response = this.http.get(fullUrl);
          break;

        case 'POST':
          response = this.http.post(
            fullUrl,
            JSON.stringify(payload)
          );
          break;

        case 'PUT':
          response = this.http.put(
            fullUrl,
            JSON.stringify(payload)
          );
          break;

        case 'DELETE':
          response = this.http.delete(fullUrl);
          break;
      }

      return response?.toPromise();

    } catch (error) {
      this.notification.error('Request error', error);

    }
  }
}
