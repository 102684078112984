import {Component, Input, OnInit} from '@angular/core';
import {Taxon} from '../../entities/taxon';
import {Literature} from '../../entities/taxon/literature';
import { environment } from '../../../environments/environment';
import {FilePreviewService} from '../../services/file-preview.service';
import {UiUpdateService} from '../../services/ui-update.service';

@Component({
  selector: 'app-literature',
  templateUrl: './literature.component.html',
  styleUrls: ['./literature.component.css']
})
export class LiteratureComponent implements OnInit {

  @Input() taxon: Taxon;

  literature: Literature = null;
  selectedIndex: number = null;
  environment = environment;

  constructor(
    readonly filePreview: FilePreviewService,
    readonly ui: UiUpdateService
  ) {
    window.addEventListener('message', (event)  => {
      if (event.data[0] === 'upload-complete' && event.data[1] === 'uploadReferenceComplete') {
        this.literature.reference = event.data[2][0].uuid;
        this.hideUploadModal();
      }
    });
  }

  ngOnInit(): void {
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
  }

  createLiterature() {
    const literature = new Literature();
    literature.taxonId = this.taxon.taxonId;

    this.taxon.literature.push(literature);
    this.literature = literature;
    this.ui.updateSelectPicker();
  }

  deleteLiterature(index: number) {
    this.taxon.literature.splice(index, 1);
    this.literature = null;
    this.ui.updateSelectPicker();
  }

  uploadReference() {
    // @ts-ignore
    document.getElementById('frame-upload2').contentWindow.postMessage(['upload-start'], '*');
  }

  showUploadModal() {
    $('#frame-upload2').attr('src', $('#frame-upload2').attr('src'));
  }

  hideUploadModal() {
    // @ts-ignore
    $('#uploadModal2').delay(500).modal('hide');
  }

}
