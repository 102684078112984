import {Injectable} from '@angular/core';
import {Notification} from '../entities/notification';
import { environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: Notification[] = [];
  environment = environment;

  constructor() { }

  createNotification(
    title: string = '',
    message: string = '',
    type: string = 'INFO'
  ) {
    const notification = new Notification();
    notification.title = title;
    notification.message = message;
    notification.type = type;
    this.notifications.push(notification);

    if (this.environment.debug) {
      if (type.toUpperCase() === 'INFO') {
        console.info(notification);
      } else if (type.toUpperCase() === 'ERROR') {
        console.error(notification);
      } else if (type.toUpperCase() === 'WARNING') {
        console.warn(notification);
      }
    }

    // @ts-ignore
    $('.toast').toast('show');
  }

  error(title: string, message: string) {
    this.createNotification(title, message, 'ERROR');
  }

  warning(title: string, message: string) {
    this.createNotification(title, message, 'WARNING');
  }

  info(title: string, message: string) {
    this.createNotification(title, message, 'INFO');
  }

  deleteNotification(index: number) {
    this.notifications.splice(index, 1);
  }
}
