<div class="input-group">
  <div class="input-group-prepend">
    <label class="input-group-text static-width-130" for="inputGroupSelect02">Prop. / Comm.</label>
  </div>
  <select  [(ngModel)]="remark" (change)="setSelectedIndex($event.target.selectedIndex)" id="inputGroupSelect02">
    <option *ngFor="let remark of taxon?.remarks; let index = index" [ngValue]="remark">{{ remark.title || '- New -' }}</option>
  </select>
  <button [class.d-none]="remark?.taxonId === null" (click)="deleteRemark(selectedIndex)" class="btn btn-outline-danger rounded-0" type="button">Delete</button>
  <div class="input-group-append">
    <button (click)="createRemark()" class="btn btn-outline-primary" type="button">+ Add</button>
  </div>
</div>

<button *ngIf="environment.debug" class="btn btn-info" data-toggle="collapse" data-target="#demo">Show Remark JSON</button>
<pre *ngIf="environment.debug" id="demo" class="collapse border p-3">
  {{ remark | json }}
</pre>

<ng-template [ngIf]="remark" >
  <div class="row" [class.d-none]="taxon?.remarks?.length <= 0">
    <div class="col">
      <hr>

      <h5>References</h5>

<!--      <div class="container-fluid border rounded bg-light p-3 m-0 mb-3">-->
<!--        <h6>Bibliography</h6>-->

<!--        <div class="input-group mb-2">-->
<!--          <div class="input-group-prepend">-->
<!--            <label class="input-group-text static-width-130" for="inputGroupInput800">Published by</label>-->
<!--          </div>-->
<!--          <input [(ngModel)]="remark.publishedByName" class="form-control" id="inputGroupInput800" type="text" placeholder="Author">-->
<!--          <label class="input-group-text rounded-0" for="inputGroupInput801">in</label>-->
<!--          <input [(ngModel)]="remark.publishedByYear" class="form-control" id="inputGroupInput801" type="number" placeholder="Year">-->
<!--        </div>-->

<!--        <div class="input-group">-->
<!--          <div class="input-group-prepend">-->
<!--            <label class="input-group-text static-width-130" for="inputGroupInput802">Title</label>-->
<!--          </div>-->
<!--          <input [(ngModel)]="remark.title" class="form-control" id="inputGroupInput802" type="text" placeholder="">-->
<!--        </div>-->

<!--      </div>-->

      <div class="container-fluid border rounded bg-light p-3 m-0">

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupInput806">Comment</label>
          </div>
          <input [(ngModel)]="remark.content" class="form-control rounded-0" type="text" id="inputGroupInput806" >
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupInput805">File</label>
          </div>
          <input readonly [(ngModel)]="remark.reference" class="form-control rounded-0" type="text" id="inputGroupInput805" >
          <button (click)="deleteReference()" [class.d-none]="remark.reference === null" class="btn btn-outline-danger rounded-0">Delete</button>
          <button (click)="showUploadModal()" data-toggle="modal" data-target="#uploadModal3" class="btn btn-outline-secondary rounded-0">Upload...</button>
          <div class="input-group-append">
            <button (click)="this.filePreview.previewFile(remark.reference)" class="btn btn-outline-secondary" [class.d-none]="remark.reference === null">Preview</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</ng-template>




<div class="modal fade" id="uploadModal3" tabindex="-1" role="dialog" aria-labelledby="uploadModal3Label" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title" id="uploadModal3Label">Upload file</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe id="frame-upload3" src="https://cdn.peterstueben.com/upload?callbackFunction=uploadRemarkComplete&automaticUpload=0" class="border-0 m-0 p-0 w-100" style="height: 370px"></iframe>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="uploadRemark()">Upload</button>
      </div>
    </div>
  </div>
</div>
