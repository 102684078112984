export class UTM {
  latitude: UTMLatitude = new UTMLatitude();
  longitude: UTMLongitude = new UTMLongitude();
}

export class UTMLatitude {
  direction: 'N'|'S' = 'N';
  degree: number = 0;
  minutes: number = 0;
  seconds: number = 0;
}

export class UTMLongitude {
  direction: 'E'|'W' = 'E';
  degree: number = 0;
  minutes: number = 0;
  seconds: number = 0;
}
