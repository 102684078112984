<div>

  <div class="row">
    <div class="col">
      <div class="input-group">
        <div class="input-group-prepend">
          <label class="input-group-text static-width-160" for="inputGroupSelect02">Complete data sets</label>
        </div>
        <select [class.d-none]="taxon?.locations?.length <= 0" [(ngModel)]="location" (change)="changeLocation($event.target.selectedIndex)" id="inputGroupSelect02">
          <option *ngFor="let location of taxon?.locations" [ngValue]="location">{{ nomenclature.getLocationCaption(location) || '- New -' }}</option>
        </select>
        <button [class.d-none]="location === null" (click)="deleteLocation(selectedIndex)" class="btn btn-outline-danger rounded-0" type="button">Delete</button>
        <div class="input-group-append">
          <button (click)="createLocation()" class="btn btn-outline-primary" type="button">+ Add</button>
        </div>
      </div>
    </div>
  </div>

  <button *ngIf="environment.debug" class="btn btn-info" data-toggle="collapse" data-target="#demo">Show Location JSON</button>
  <pre *ngIf="environment.debug" id="demo" class="collapse border p-3">
    {{ location | json }}
  </pre>

  <ng-template [ngIf]= "location" >
   <div class="row" [class.d-none]="location === null">
    <div class="col">
      <hr>

      <h5>Locational data</h5>
      <div class="container-fluid border rounded bg-light p-3 m-0">
        <h6>Position</h6>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text static-width-130">Latitude</span>
          </div>

            <input [(ngModel)]="location.latitude" id="input-location-latitude" type="number" class="form-control" placeholder="Decimal" aria-label="">

          <div class="input-group-append">
            <span class="input-group-text static-width-130">Longitude</span>
          </div>

            <input [(ngModel)]="location.longitude" id="input-location-longitude" type="number" class="form-control" placeholder="Decimal" aria-label="">

          <div class="input-group-append">
            <button class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModal">From UTM format...</button>
          </div>
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput07">Altitude</label>
            <label class="input-group-text rounded-0" for="inputGroupInput07">from</label>
          </div>

            <input [(ngModel)]="location.altitudeFrom" class="form-control" id="inputGroupInput07" type="number" placeholder="Meters">

          <label class="input-group-text rounded-0" for="inputGroupInput07">m</label>
          <label class="input-group-text rounded-0" for="inputGroupInput08">to</label>

            <input [(ngModel)]="location.altitudeTo" class="form-control" id="inputGroupInput08" type="number" placeholder="Meters">

          <label class="input-group-text rounded-0" for="inputGroupInput08">m</label>
        </div>

      </div>

      <div class="container-fluid border rounded bg-light p-3 m-0 mt-3">
        <h6>Geographical information</h6>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupSelect05">Country</label>
          </div>

            <select [(ngModel)]="location.geoCountry" id="inputGroupSelect05">
              <option>- None -</option>
              <option *ngFor="let country of countries" [ngValue]="country.nameEN" [selected]="location?.geoCountry === country.nameEN">{{ country.nameEN }}</option>
            </select>

        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput09">Region</label>
          </div>
          <input type="text" [(ngModel)]="location.geoRegion" class="form-control" id="inputGroupInput09">
        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput10">City</label>
          </div>
          <input type="text" [(ngModel)]="location.geoCity" class="form-control" id="inputGroupInput10">
        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput11">Locality</label>
          </div>
          <input type="text" [(ngModel)]="location.geoSpot" class="form-control" id="inputGroupInput11">
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput12">Plant</label>
          </div>
          <input type="text" [(ngModel)]="location.plant" class="form-control" id="inputGroupInput12">
        </div>

      </div>

      <div class="container-fluid border rounded bg-light p-3 m-0 mt-3">
        <h6>Collection information</h6>

        <div class="custom-control custom-checkbox mb-2">
          <input (change)="location.locusTypicus = $event.target.checked ? '1' : '0'" [checked]="location.locusTypicus === '1'" value="1" type="checkbox" class="custom-control-input" id="customCheck1">
          <label class="custom-control-label" for="customCheck1">Locus typicus</label>
        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput133">Date</label>
          </div>

          <input type="text" [(ngModel)]="location.dateRecorded" class="form-control" id="inputGroupInput133">

        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput13">Quantity</label>
          </div>

            <input type="number" [(ngModel)]="location.quantity" class="form-control" id="inputGroupInput13">

        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupSelect06">Collected by</label>
          </div>
          <input [(ngModel)]="location.collectedBy" type="text" class="form-control" id="inputGroupSelect06">

        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput14">Collection</label>
          </div>

            <input type="text" [(ngModel)]="location.collection" class="form-control" id="inputGroupInput14">

        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupSelect07">Determined by</label>
          </div>
          <input [(ngModel)]="location.determinedBy" type="text" class="form-control" id="inputGroupSelect07">

        </div>

      </div>

      <div class="container-fluid border rounded bg-light p-3 m-0 mt-3">
        <h6>DNA</h6>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput15">CO1</label>
          </div>

            <input type="text" [(ngModel)]="location.dnaSequenceCO1" class="form-control" id="inputGroupInput15">

        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput16">16S</label>
          </div>

            <input type="text" [(ngModel)]="location.dnaSequence16S" class="form-control" id="inputGroupInput16">

        </div>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput17">28S</label>
          </div>

            <input type="text" [(ngModel)]="location.dnaSequence28S" class="form-control" id="inputGroupInput17">

        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupInput18">Information</label>
          </div>

            <textarea [(ngModel)]="location.comments" id="inputGroupInput18" class="form-control">{{ location?.comments }}</textarea>

        </div>

      </div>


    </div>
  </div>
  </ng-template>
</div>



<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h5 class="modal-title" id="exampleModalLabel">From UTM format</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupSelect03">Latitude</label>
          </div>
          <select [(ngModel)]="utm.latitude.direction" id="inputGroupSelect03">
            <option value="N">N</option>
            <option value="S">S</option>
          </select>
          <input [(ngModel)]="utm.latitude.degree" class="form-control" id="inputGroupInput01" type="number" placeholder="Degree">
          <label class="input-group-text rounded-0" for="inputGroupInput01">°</label>
          <input [(ngModel)]="utm.latitude.minutes" class="form-control" id="inputGroupInput02" type="number" placeholder="Minutes">
          <label class="input-group-text rounded-0" for="inputGroupInput02">'</label>
          <input [(ngModel)]="utm.latitude.seconds" class="form-control" id="inputGroupInput03" type="number" placeholder="Seconds">
          <label class="input-group-text rounded-0" for="inputGroupInput03">"</label>
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text static-width-130" for="inputGroupSelect04">Longitude</label>
          </div>
          <select [(ngModel)]="utm.longitude.direction" id="inputGroupSelect04">
            <option value="E">E</option>
            <option value="W">W</option>
          </select>
          <input [(ngModel)]="utm.longitude.degree" class="form-control" id="inputGroupInput04" type="number" placeholder="Degree">
          <label class="input-group-text rounded-0" for="inputGroupInput04">°</label>
          <input [(ngModel)]="utm.longitude.minutes" class="form-control" id="inputGroupInput05" type="number" placeholder="Minutes">
          <label class="input-group-text rounded-0" for="inputGroupInput05">'</label>
          <input [(ngModel)]="utm.longitude.seconds" class="form-control" id="inputGroupInput06" type="number" placeholder="Seconds">
          <label class="input-group-text rounded-0" for="inputGroupInput06">"</label>
        </div>
      </div>
      <div class="modal-footer bg-light">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button (click)="convertLatLongToUTM()" type="button" data-dismiss="modal" class="btn btn-primary">Apply</button>
      </div>
    </div>
  </div>
</div>
