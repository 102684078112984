export class Literature {
  id: number = null;
  taxonId: number = null;
  version: number = null;
  publishedByName: string = '';
  publishedByYear: number = null;
  title: string = null;
  literatureType: string = 'FR';
  content: string = null;
  reference: string = null;
  referenceType: string = null;
  dateCreated: string = null;
  dateUpdated: string = null;
  dateReleased: string = null;
  dateExpires: string = null;
  status: string = null;
}
