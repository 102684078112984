import {Component, Input, OnInit} from '@angular/core';
import {NomenclatureService} from '../../services/nomenclature.service';
import {Taxon} from '../../entities/taxon';
import {Taxonomy} from '../../entities/taxonomy';
import {Country} from '../../entities/country';
import {Author} from '../../entities/author';
import {Location} from '../../entities/taxon/location';
import {ConversionService} from '../../services/conversion.service';
import {UTM} from '../../entities/utm';
import { environment } from '../../../environments/environment';
import {UiUpdateService} from '../../services/ui-update.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  @Input() taxon: Taxon;
  @Input() taxonomy: Taxonomy;
  @Input() countries: Country[];
  @Input() authors: Author[];
  location: Location = null;
  selectedIndex: number = null;
  utm: UTM = new UTM();
  environment = environment;

  constructor(
    readonly nomenclature: NomenclatureService,
    readonly conversion: ConversionService,
    readonly ui: UiUpdateService
  ) { }

  changeLocation(index: number) {
    this.utm = new UTM();
    this.selectedIndex = index;
  }

  convertLatLongToUTM() {
    const latlong = this.conversion.convertLatLongToUTM(this.utm);
    this.location.latitude = latlong.latitude;
    this.location.longitude = latlong.longitude;
  }

  createLocation() {
    const location = new Location();
    location.taxonId = this.taxon.taxonId;

    this.taxon.locations.push(location);
    this.location = location;

    this.ui.updateSelectPicker();
  }

  deleteLocation(index: number) {
    this.taxon.locations.splice(index, 1);
    this.location = null;
    this.ui.updateSelectPicker();
  }

  ngOnInit(): void {
  }

}
