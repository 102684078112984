import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilePreviewService {

  constructor() { }

  previewFile(uuid: string) {
    window.open(environment.cdnBaseUrl + '/' + uuid);
  }

  previewLink(url: string) {
    window.open(url);
  }
}
