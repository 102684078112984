import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UiUpdateService {

  myTimeout: number;

  constructor() { }

  updateSelectPicker(): void {
    this.myTimeout = setTimeout(this.delegateUpdateSelectPicker, 50);
  }

  private delegateUpdateSelectPicker(selector: string = 'select') {
    $(selector).selectpicker('refresh');
    console.log('refresh');
    clearTimeout(this.myTimeout);
  }
}
