import { Injectable } from '@angular/core';
import {UTM} from '../entities/utm';
import {LatLong} from '../entities/latlong';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  constructor() { }

  convertLatLongToUTM(utm: UTM): LatLong {
    const latlong = new LatLong();
    latlong.latitude = (utm.latitude.degree + ((utm.latitude.minutes + (utm.latitude.seconds / 60)) / 60)) * (utm.latitude.direction === 'N' ? 1 : -1);
    latlong.longitude = (utm.longitude.degree + ((utm.longitude.minutes + (utm.longitude.seconds / 60)) / 60)) * (utm.longitude.direction === 'E' ? 1 : -1);

    return latlong;
  }
}
