import { Injectable } from '@angular/core';
// @ts-ignore
import {Taxon} from '../entities/taxon';
import {Location} from '../entities/taxon/location';

@Injectable({
  providedIn: 'root'
})
export class NomenclatureService {

  constructor() { }

  getLocationCaption(location: Location): string {
    const parts = [];

    if (this.isValueSet(location?.quantity)) { parts.push(location?.quantity + ' Ex.'); }
    if (this.isValueSet(location?.geoCountry)) { parts.push(location?.geoCountry); }
    if (this.isValueSet(location?.geoRegion)) { parts.push(location?.geoRegion); }
    if (this.isValueSet(location?.geoCity)) { parts.push(location?.geoCity); }
    if (this.isValueSet(location?.geoSpot)) { parts.push(location?.geoSpot); }
    if (this.isValueSet(location?.plant)) { parts.push(location?.plant); }
    if (this.isValueSet(location?.dateRecorded)) { parts.push(location?.dateRecorded); }
    if (this.isValueSet(location?.collectedBy)) { parts.push(location?.collectedBy); }
    if (this.isValueSet(location?.collection)) { parts.push(location?.collection); }
    if (this.isValueSet(location?.determinedBy)) { parts.push(location?.determinedBy); }
    if (this.isValueSet(location?.dnaSequenceCO1)) { parts.push('CO1'); }
    if (this.isValueSet(location?.dnaSequence16S)) { parts.push('16S'); }
    if (this.isValueSet(location?.dnaSequence28S)) { parts.push('28S'); }
    if (this.isValueSet(location?.comments)) { parts.push(location?.comments); }


    return parts.join(', ');

  }

  getTaxonCaption(taxon: Taxon, formatHTML: boolean = false) {
    let nomenclature = '';

    if (taxon.genus && taxon.genus !== 'null') {
      nomenclature =
        (formatHTML ? '<i>' : '') +
        taxon.genus.charAt(0).toUpperCase() + taxon.genus.toLowerCase().slice(1) + ' ' +
      (formatHTML ? '</i>' : '');

      if (taxon.subGenus && taxon.subGenus !== 'null') {
        nomenclature += ' (' +
        (formatHTML ? '<i>' : '') +
          taxon.subGenus.toLowerCase() +
        (formatHTML ? '</i>' : '') +
        ') ';
      }

      if (taxon.species && taxon.species !== 'null') {
        nomenclature +=
          (formatHTML ? '<i>' : '') +
            taxon.species.toLowerCase() + ' ' +
        (formatHTML ? '</i>' : '');
      }

      if (taxon.subSpecies && taxon.subSpecies !== 'null') {
        nomenclature +=
          (formatHTML ? '<i>' : '') +
          taxon.subSpecies.toLowerCase() + ' ' +
        (formatHTML ? '</i>' : '');
      }

      if (taxon.describedByName && taxon.describedByName !== 'null') {

        if (taxon.describedByOriginalSource === '1') {
          nomenclature += '(';
        }

        nomenclature += taxon.describedByName;

        if (taxon.describedByYear && !isNaN(<number>taxon.describedByYear) && taxon.describedByYear.toString().length === 4) {
          nomenclature += ', ' + taxon.describedByYear;
        }

        if (taxon.describedByOriginalSource === '1') {
          nomenclature += ')';
        }
      }

    }

    return nomenclature.trim();
  }

  isValueSet(value?: any): boolean {
    return (typeof value === 'string' ? value?.trim() : value) !== '' &&
      value !== null &&
      value !== 'null' &&
      value !== 'undefined' &&
      typeof value !== 'undefined'
    ;

  }
}
